import httpClient from "./httpClient";

const MONGO_TRACK_STATS = "/mongo-track-stats";
const SEARCH_PAGE = "/search-page";

const getTop10TracksFromMongo = (spotifyID, limit) =>
  httpClient.get(`${MONGO_TRACK_STATS}/track-detail/${spotifyID}/${limit}`);

const getTrackDetailsBySpotifyIdAndTrackId = (spotifyID, trackID) =>
  httpClient.get(
    `${MONGO_TRACK_STATS}/track/${spotifyID}?chartmetricTrackID=${trackID}`
  );

const getTracksSpotifyStreams = (spotifyID, trackID, years, order, platform) =>
  httpClient.get(
    `${MONGO_TRACK_STATS}/track-stats/${spotifyID}/${years}/${order}/${platform}?chartmetricTrackID=${trackID}`
  );

const getTrackStatsWeeklyDiffBySpotifyID = (
  spotifyID,
  trackID,
  years,
  order,
  platform
) =>
  httpClient.get(
    `${MONGO_TRACK_STATS}/weekly-track-stats/${spotifyID}/${years}/${order}/${platform}?chartmetricTrackID=${trackID}`
  );

const getTracksLabels = (label, limit) =>
  httpClient.get(
    `${SEARCH_PAGE}/artist-track-labels?label=${label}&limit=${limit}`
  );

const searchTracksByLabel = (payload) =>
  httpClient.post(`${SEARCH_PAGE}/search-artist-track-labels`, payload);

const addTracksBySpotifyIdAndTrackId = (payload, spotifyID) =>
  httpClient.post(`${MONGO_TRACK_STATS}/add-track-stats/${spotifyID}`, payload);

export {
  getTop10TracksFromMongo,
  getTrackDetailsBySpotifyIdAndTrackId,
  getTracksSpotifyStreams,
  getTrackStatsWeeklyDiffBySpotifyID,
  getTracksLabels,
  searchTracksByLabel,
  addTracksBySpotifyIdAndTrackId,
};
