<template>
  <div class="search-tracks">
    <div class="d-flex justify-content-between align-items-center">
      <el-select
        class="w-100"
        v-model="selectedLabels"
        multiple
        filterable
        remote
        allow-create
        reserve-keyword
        placeholder="Select Labels to Search Tracks"
        effect="dark"
        :remote-method="searchLabels"
        :loading="loading"
      >
        <el-option
          v-for="(label, i) in labels"
          :key="i"
          :label="label"
          :value="label"
        >
          <el-checkbox
            :model-value="selectedLabels.includes(label)"
            @click="(e) => e.preventDefault()"
          >
            {{ label }}
          </el-checkbox>
        </el-option>
      </el-select>
      <button
        :disabled="selectedLabels.length === 0"
        @click="searchTracks"
        class="ms-8 btn btn-sm btn-primary btn-style ps-16 pe-16 pt-3 pb-3"
        style="border-radius: 4px"
      >
        Search
      </button>
    </div>
    <div class="mt-5">
      <CardToolbar
        title="Top Tracks by Labels"
        :show-toolbar-buttons="false"
        :fontStyle="true"
        :padding="false"
        :shadow-class="true"
        :margin="true"
        v-loading="tableLoading"
      >
        <template v-slot:body>
          <el-table
            @sort-change="sortResults"
            :data="tableData"
            :fit="true"
            size="medium"
            stripe
            style="width: 100%"
            header-cell-class-name="table-header-text"
            :row-class-name="tableRowClassName"
            :cell-class-name="tableCellClassName"
          >
            <el-table-column
              label="Track"
              width="300"
              prop="trackName"
              sortable="custom"
              fixed
            >
              <template #default="scope">
                <div
                  class="d-flex align-items-center cursor-pointer"
                  @click="
                    routeToPage(
                      `song/${scope.row.spotifyID}/${scope.row.chartmetricTrackID}`,
                      true
                    )
                  "
                >
                  <el-avatar :size="25" :src="scope.row.imageURL"></el-avatar>
                  <span
                    class="ms-2 truncate"
                    data-tag="span"
                    title=""
                    data-tooltip="true"
                    :data-text="
                      scope.row.trackName ? scope.row.trackName : 'N/A'
                    "
                  >
                    {{ scope.row.trackName ? scope.row.trackName : "N/A" }}
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Artist"
              width="200"
              prop="artistName"
              sortable="custom"
            >
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    scope.row.artistName && scope.row.artistName.length
                      ? scope.row.artistName
                      : 'N/A'
                  "
                >
                  {{
                    scope.row.artistName && scope.row.artistName.length
                      ? scope.row.artistName.toString()
                      : "N/A"
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Label"
              width="200"
              prop="label"
              sortable="custom"
            >
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="scope.row.label ? scope.row.label : 'N/A'"
                >
                  {{ scope.row.label ? scope.row.label : "N/A" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Total Spotify Streams"
              width="230"
              prop="spotifyPlays"
              sortable="custom"
            >
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    checkIsValueAvailableOrNot(
                      'key',
                      null,
                      scope.row.spotifyPlays
                    )
                  "
                >
                  {{
                    checkIsValueAvailableOrNot(
                      "key",
                      null,
                      scope.row.spotifyPlays
                    )
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Spotify Streams Last Month"
              width="230"
              prop="spotifyPlaysByMonth"
              sortable="custom"
            >
              <template #default="scope">
                <p
                  class="truncate"
                  data-tag="p"
                  title=""
                  data-tooltip="true"
                  :data-text="
                    checkIsValueAvailableOrNot(
                      'key',
                      null,
                      scope.row.spotifyPlaysByMonth
                    )
                  "
                >
                  {{
                    checkIsValueAvailableOrNot(
                      "key",
                      null,
                      scope.row.spotifyPlaysByMonth
                    )
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="Release Date"
              align="right"
              prop="releaseDate"
              sortable="custom"
            >
              <template #default="scope">
                {{
                  scope.row.releaseDate
                    ? moment(scope.row.releaseDate).format("MMM DD, YYYY")
                    : "N/A"
                }}
              </template>
            </el-table-column>
          </el-table>
          <!--          <el-pagination-->
          <!--            class="mt-7 mb-7"-->
          <!--            v-model:currentPage="paginationObj.offset"-->
          <!--            v-model:page-size="paginationObj.limit"-->
          <!--            :page-sizes="[10, 20, 50, 100, 200]"-->
          <!--            :page-size="paginationObj.limit"-->
          <!--            :small="true"-->
          <!--            layout="total, sizes, prev, pager, next"-->
          <!--            :total="paginationObj.total"-->
          <!--            @size-change="paginationChanged"-->
          <!--            @current-change="paginationChanged"-->
          <!--          >-->
          <!--          </el-pagination>-->
          <div
            @click="showMoreTracks"
            class="
              cursor-pointer
              d-flex
              justify-content-center
              mt-4
              mb-1
              p-2
              fw-bolder
              theme-v2-color
            "
            style="background: #dee4ee; border-radius: 4px"
          >
            + Show More
          </div>
        </template>
      </CardToolbar>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { getTracksLabels, searchTracksByLabel } from "@/api/mongoTracks";
import CardToolbar from "@/common/components/CardToolbar.vue";
import {
  checkIsValueAvailableOrNot,
  getUniqueValuesFromArray,
  routeToPage,
  tableCellClassName,
  tableRowClassName,
} from "@/utils/staticHelper";
import moment from "moment";
import { sortArray } from "@/aiBetaBench/helpers";

export default {
  name: "SearchTracksByLabels",
  methods: {
    checkIsValueAvailableOrNot,
    routeToPage,
    tableCellClassName,
    tableRowClassName,
  },
  components: { CardToolbar },
  setup() {
    const loading = ref(false);
    const tableLoading = ref(false);
    const labels = ref([]);
    const selectedLabels = ref([]);
    const tableData = ref([]);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const tracksLimit = ref(20);
    // const tableDataWithOutPagination = ref([]);
    // const paginationObj = ref({
    //   offset: 1,
    //   limit: 50,
    //   total: 0,
    //   sortBy: "spotifyPlays",
    //   sortOrder: "desc",
    // });

    // onMounted(async () => {
    //   try {
    //     loading.value = true;
    //     const { data } = await getTracksLabels();
    //     labels.value = data;
    //   } catch (e) {
    //     console.log(e);
    //   } finally {
    //     loading.value = false;
    //   }
    // });

    const searchLabels = async (query, limit = 50) => {
      if (query) {
        try {
          loading.value = true;
          const { data } = await getTracksLabels(query, limit);
          labels.value = data;
        } catch (e) {
          console.log(e);
        } finally {
          loading.value = false;
        }
      }
    };

    const searchTracks = async () => {
      try {
        tableLoading.value = true;
        const { data } = await searchTracksByLabel({
          labels: selectedLabels.value,
          limit: tracksLimit.value,
        });
        tableData.value = data;
        // paginationObj.value.total = tableDataWithOutPagination.value.length;
        // paginationChanged();
      } catch (e) {
        console.log(e);
      } finally {
        tableLoading.value = false;
      }
    };

    const showMoreTracks = () => {
      tracksLimit.value += 20;
      searchTracks();
    };

    const sortResults = ({ prop, order }) => {
      tableLoading.value = true;
      sortBy.value = prop;
      sortDir.value = order;
      if (sortBy.value && sortDir.value) {
        tableData.value = sortArray(
          sortBy.value,
          sortDir.value,
          JSON.parse(JSON.stringify(tableData.value))
        );
      }
      // paginationChanged();
      tableLoading.value = false;
    };

    // const paginationChanged = () => {
    //   tableData.value = paginate(
    //     tableDataWithOutPagination.value,
    //     paginationObj.value.limit,
    //     paginationObj.value.offset
    //   );
    // };

    return {
      loading,
      tableLoading,
      selectedLabels,
      labels,
      tableData,
      // paginationObj,
      searchLabels,
      searchTracks,
      moment,
      sortResults,
      showMoreTracks,
      // paginationChanged,
    };
  },
};
</script>
